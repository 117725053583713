import React from "react"
import { Footer, Coords } from "@lib/simba/_statics"
import styled from "styled-components"
import Layout from "@utils/layout"
import { graphql } from "gatsby"
import { HeaderInner } from "@lib/simba/header"
import Seo from "@components/Seo"
import { LISTING } from "@utils/get-schemas"
import getSeo from "@utils/get-seo"
import ListingElement from "@lib/simba/listing-element"
import createLink from "@utils/create-link"
import { Structure } from "@lib/simba/_statics"
import BackgroundImage from "gatsby-background-image"

const Grid = styled.section`
  display: grid;
  padding: ${({ theme }) => theme.paddingBox};
  gap: ${({ theme }) => theme.paddingBox};
  grid-template-columns: 1fr;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.maxWidth};

    grid-template-columns: repeat(4, 1fr);
    margin: ${({ theme }) => `${theme.paddingBox} auto`};
    gap: ${({ theme }) => theme.paddingBox};
    padding: 0;
  }
`

const getTitle = ({ title, page }) =>
  page > 1 ? `${title} - Page ${page}` : title

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 33vh;
  margin-top: -60px;
`

const Listing = ({ data, pageContext }) => (
  <Structure hideFooterArticle={true}>
    <Seo seo={getSeo(data.sanityCategory, LISTING)} />
    {data.sanityCategory.cover && (
      <StyledBackgroundImage fluid={data.sanityCategory.cover.asset.fluid} />
    )}
    <Grid>
      {data.actualites.nodes.map(({ cover, slug, title, resume }) => (
        <ListingElement
          fluid={cover && cover.asset.fluid}
          link={createLink("SanityContenu", slug.current)}
          title={title}
          categoryTitle={data.sanityCategory.title}
          resume={resume}
        />
      ))}
    </Grid>
  </Structure>
)

export default Listing

export const query = graphql`
  query ListingContent($slug: String, $skip: Int!, $limit: Int!) {
    actualites: allSanityContenu(
      filter: {
        categories: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        subtitle
        resume
        categories {
          title
        }
        slug {
          current
        }
        cover {
          asset {
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sanityCategory(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      cover {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
