import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Title = styled.div`
  font-weight: bold;
  font-size: 6vw;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 1.2vw;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 200px;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colorText};
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  padding: ${({ theme }) =>
    `${theme.paddingBox} ${theme.paddingBox} calc(${theme.paddingBox}/2)`};
`

const Category = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorLink};
  font-size: 3vw;
  letter-spacing: 2px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 0.8vw;
  }
`

const Resume = styled.div`
  font-size: 5vw;
  white-space: pre-wrap;
  line-height: 1.33;
  padding: ${({ theme }) =>
    `calc(${theme.paddingBox}/2) 0 ${theme.paddingBox}`};
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 1vw;
  }
`

const Footer = styled.div`
  align-self: flex-end;
  text-transform: uppercase;
  color: #bbb;
  font-size: 3vw;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 0.8vw;
  }
`

const InLink = styled(Link)`
  color: ${({ theme }) => theme.colorLink};
`

const El = ({ fluid, title, link, categoryTitle, resume }) => (
  <StyledLink to={link}>
    <StyledBackgroundImage fluid={fluid} />
    <Container>
      <Category>{categoryTitle}</Category>
      <Title>{title}</Title>
      <Resume>{resume}</Resume>
      <InLink to={link}>Lire l'article</InLink>
      <Footer>TD Elec</Footer>
    </Container>
  </StyledLink>
)

export default El
